.main-content, .navbar .container-fluid {
  margin-right: 20px;
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

.btn {
  transition: padding-right .3s linear;
}

.btn.sending:not(.disabled) {
  position: relative;
  padding-right: 40px;
}
.btn.sending:not(.disabled):before {
  content: '';
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-left-color: transparent;
  border-radius: 50%;
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
}
.btn.sending.btn-block:not(.disabled):before {
  right: 15px;
}

.btn.sending:not(.disabled):after {
  content: '';
  width: 0px;
  transition-delay: 0.5s;
  transition-duration: 0.75s;
  transition-property: width;
}

.btn.sending:not(.disabled):after {
  content: '';

  height: 100%;
  width: 0px;
  transition-delay: 0.5s;
  transition-duration: 0.75s;
  transition-property: width;
}

.btn.sending:not(.disabled) {
  pointer-events: none;
  cursor: not-allowed;
}

.btn.sending:not(.disabled):not(.expand):not(.btn-block) {
  text-align: left;
}

.btn.sending:not(.disabled):before {
  transition-delay: 0.5s;
  transition-duration: 1s;
  opacity: 1;
}
.btn.sending:not(.disabled):after {
  transition-delay: 0s;
  width: 20px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 20px;
  height: 20px;
  top: -8px;
  border-radius: 10px;
}

  .custom-slider .ngx-slider .ngx-slider-pointer:after {
      display: none;
  }

.custom-slider .ngx-slider .ngx-slider-bar {
  height: 2px;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 8px;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  top: -1px;
}

.input-group>.input-group-append>.dropdown-toggle {
  padding-bottom: 6px;
}

.table-sm.um td, .table-sm.um th { //ultra small
  padding: .3rem 1rem;
}

.input-group:not(.has-validation)>.form-control:disabled, .input-group:not(.has-validation)>.form-control[readonly] {
  border-radius: .375em;
}

