/* Alert */
.alert-warning2 {
    color: #283e59;
    background-color: #f6f143;
    border-color: #f6c343;
}

/* btn green */
.btn-green{
    color:#fff;
    background-color:#1a6311;
    border-color:#083606;
}

.btn-green:hover{
    color:#000;
    background-color:#2a961c;
    border-color:#05310d;
}

.btn-green.focus,.btn-green:focus{
    color:#fff;
    background-color:#15500d;
    border-color:#083606;
    box-shadow:0 0 0 .15rem rgba(76,143,233,.5);
}

.btn-green.disabled,.btn-green:disabled{
    color:#fff;
    background-color:#2a961c;
    border-color:rgb(103, 117, 96);
}

.btn-green:not(:disabled):not(.disabled).active,.btn-green:not(:disabled):not(.disabled):active,.show>.btn-green.dropdown-toggle{
    color:#fff;
    background-color:#2a961c;
    border-color:rgb(103, 117, 96);
}

.btn-green:not(:disabled):not(.disabled).active:focus,.btn-green:not(:disabled):not(.disabled):active:focus,.show>.btn-green.dropdown-toggle:focus{
    box-shadow:0 0 0 .15rem rgba(76,143,233,.5);
}

/* btn yellow */
.btn-yellow{
    color:#000;
    background-color:#eeff00;
    border-color:#b46c00;
}

.btn-yellow:hover{
    color:#000;
    background-color:#aab600;
    border-color:#05310d;
}

.btn-yellow.focus,.btn-yellow:focus{
    color:#000;
    background-color:#becc00;
    border-color:#083606;
    box-shadow:0 0 0 .15rem rgba(76,143,233,.5);
}

.btn-yellow.disabled,.btn-yellow:disabled{
    color:rgb(58, 58, 58);
    background-color:#c0aa46;
    border-color:rgb(103, 117, 96);
}

.btn-yellow:not(:disabled):not(.disabled).active,.btn-yellow:not(:disabled):not(.disabled):active,.show>.btn-yellow.dropdown-toggle{
    color:rgb(58, 58, 58);
    background-color:#c0aa46;
    border-color:rgb(103, 117, 96);
}

.btn-yellow:not(:disabled):not(.disabled).active:focus,.btn-yellow:not(:disabled):not(.disabled):active:focus,.show>.btn-yellow.dropdown-toggle:focus{
    box-shadow:0 0 0 .15rem rgba(255, 251, 0, 0.5);
}

/* btn red */
.btn-red{
    color:#fff;
    background-color:#ff0000;
    border-color:#4b0606;
}

.btn-red:hover{
    color:#ffffff;
    background-color:#ad0000;
    border-color:#310505;
}

.btn-red.focus,.btn-red:focus{
    color:#fff;
    background-color:#d00000;
    border-color:#360606;
    box-shadow:0 0 0 .15rem rgba(233, 76, 76, 0.5);
}

.btn-red.disabled,.btn-red:disabled{
    color:#fff;
    background-color:#af3c3c;
    border-color:rgb(117, 96, 96);
}

.btn-red:not(:disabled):not(.disabled).active,.btn-red:not(:disabled):not(.disabled):active,.show>.btn-red.dropdown-toggle{
    color:#fff;
    background-color:#961c1c;
    border-color:rgb(117, 96, 96);
}

.btn-red:not(:disabled):not(.disabled).active:focus,.btn-red:not(:disabled):not(.disabled):active:focus,.show>.btn-red.dropdown-toggle:focus{
    box-shadow:0 0 0 .15rem rgba(233, 175, 76, 0.5);
}

/* btn green */
.btn-green{
    color:#fff;
    background-color:#026b00;
    border-color:#0d3d04;
}

.btn-green:hover{
    color:#ffffff;
    background-color:#015a00;
    border-color:#310505;
}

.btn-green.focus,.btn-green:focus{
    color:#fff;
    background-color:#025100;
    border-color:#360606;
    box-shadow:0 0 0 .15rem rgba(233, 76, 76, 0.5);
}

.btn-green.disabled,.btn-green:disabled{
    color:#fff;
    background-color:#486f47;
    border-color:rgb(117, 96, 96);
}

.btn-green:not(:disabled):not(.disabled).active,.btn-green:not(:disabled):not(.disabled):active,.show>.btn-green.dropdown-toggle{
    color:#fff;
    background-color:#015a00;
    border-color:rgb(117, 96, 96);
}

.btn-green:not(:disabled):not(.disabled).active:focus,.btn-green:not(:disabled):not(.disabled):active:focus,.show>.btn-green.dropdown-toggle:focus{
    box-shadow:0 0 0 .15rem rgba(233, 175, 76, 0.5);
}

/* btn blue */
.btn-blue{
    color:#fff;
    background-color:#123763;
    border-color:#ffffff;
}

.btn-blue:hover{
    color:#ffffff;
    background-color:#0b2442;
    border-color:rgb(6, 62, 99);
}

.btn-blue.focus,.btn-blue:focus{
    color:#fff;
    background-color:#0b2442;
    border-color:#360606;
    box-shadow:0 0 0 .15rem rgba(233, 76, 76, 0.5);
}

.btn-blue.disabled,.btn-blue:disabled{
    color:#fff;
    background-color:#0b2442;
    border-color:rgb(117, 96, 96);
}

.btn-blue:not(:disabled):not(.disabled).active,.btn-blue:not(:disabled):not(.disabled):active,.show>.btn-blue.dropdown-toggle{
    color:#fff;
    background-color:#0b2442;
    border-color:rgb(117, 96, 96);
}

.btn-blue:not(:disabled):not(.disabled).active:focus,.btn-blue:not(:disabled):not(.disabled):active:focus,.show>.btn-blue.dropdown-toggle:focus{
    box-shadow:0 0 0 .15rem rgba(16, 78, 184, 0.5);
}

.badge-large {
    font-size: 1em !important;
}

/* tag <a>  hover */
.btn-link:hover {
    cursor: pointer;
}

/* SELECT DISABLED */
/* .disabledSelect {
    color: #24446b !important;
    background-color: #142e4d !important;
} */
.disabledSelect {
    color: #24446b !important;
    background-color: grey !important;
}

.custom-card-title {
    margin-top: .5em;
    font-weight: bold;
}


/* popover */
.popover {
    max-width: none !important;
}

/* Botões */
.btn-bigger {
    height: 7em;
    background-color: transparent;
    border: 1px solid #1e3a5c;
    border-radius: 0.5em;
    width: 100%;
    color: #fff;
}

/* Bordas */
.border-black {
    text-shadow: 0.08em 0.08em 0.2em #494949;
    -moz-text-shadow: 0.08em 0.08em 0.2em #494949;
    -webkit-text-shadow: 0.08em 0.08em 0.2em #494949;
}

.border-white {
    text-shadow: 0em 0em 0.1em #ffffff;
    -moz-text-shadow: 0em 0em 0.1em #ffffff;
    -webkit-text-shadow: 0em 0em 0.1em #ffffff;
}

.border-red {
    text-shadow: 0.1em 0.1em 0.1em #ff0000;
    -moz-text-shadow: 0.1em 0.1em 0.1em #ff0000;
    -webkit-text-shadow: 0.1em 0.1em 0.1em #ff0000;
}

.border-yellow {
    text-shadow: 0em 0em 0.2em #fffb00;
    -moz-text-shadow: 0em 0em 0.2em #fffb00;
    -webkit-text-shadow: 0em 0em 0.2em #fffb00;
}

.border-green {
    text-shadow: 0em 0em 0.2em #2fff00;
    -moz-text-shadow: 0em 0em 0.2em #2fff00;
    -webkit-text-shadow: 0em 0em 0.2em #2fff00;
}

@media print
{
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
        print-color-adjust: exact !important;                 /*Firefox*/
    }

    .table {
        color: black !important;
    }

    .printHide {
        display: none;
    }
}